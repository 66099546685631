<template>
  <div>
    <div class="modal-tnc modal fade in" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content popup-tnc-bbb">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <img src="/static/img/big-bang-boom/tnc-modal-button-close.svg" @click.prevent="$emit('close')" />
            </button>
            <img src="/static/img/big-bang-boom/tnc-modal-text.svg" />
          </div>
          <div style="display: flex; width: 100%; justify-content: center; padding-bottom: 60px">
            <button class="button-tutup" @click.prevent="$emit('close')">Tutup</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade in"></div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
};
</script>

<style lang="scss" scoped>
.modal-tnc {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 60px 45px;
  width: 80vw;
}
.modal-tnc > * {
  width: 100%;
  margin: 0;
}
.popup-tnc-bbb {
  background-color: black;
  .button-tutup {
    background: #d51d6e;
    border-radius: 48px;
    padding: 30px 120px;
    margin: 0 auto;
    color: white;
    font-family: 'lato-bold';
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;
  }
  .modal-header,
  .modal-footer {
    border: 0;
  }
  .modal-body {
    position: relative;
    width: 100%;
    padding: 60px;
    .close {
      opacity: 1;
      position: absolute;
      top: -50px;
      right: -45px;
      z-index: 151;
    }
    img {
      object-fit: cover;
      width: 100%;
    }
  }
}
</style>
